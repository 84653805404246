import React from 'react';
import Layout from "../../components/layout";

class PastMessagesFromSenseiRavey extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Past messages from Sensei Ravey</h1>
                <hr/>
                <h1>TOGKA 2006 DANSHA Grading</h1>
                <h3>By Sensei Graham Ravey</h3>

                <p>A Dansha grading was conducted on the 22nd April 2006 by Sensei Graham Ravey.</p>

                <p>The participants were David Martin going for 3nd Dan, Brad Groves and Ashley Stevenson for 2nd Dan,
                    Brad Doust and Jacinda Wilson for 1st Dan, Mac Hadziosmanovic for 1st Kyu, Tom Mayfield (IOGKF) for
                    3rd Kyu.</p>

                <p>Congratulations to all my students who graded this day, I felt very proud of you all. Some of you
                    have waited years since your last grading, but this can only benefit you in the long run.</p>

                <p>Jacinda Wilson and Brad Doust did the 16 man fight, which is compulsory as part of the TOGKA shodan
                    test. (I bet you both ached the next day).</p>

                <p>All fights were conducted with good control shown from all Karate-ka involved, only bruises and
                    aching muscles were sustained (as it should be).</p>

                <p>Mac Hadziosmanovic and Tom Mayfield did well in their 8 man fight for brown belt, with Mac showing
                    some great Samurai spirit when he came under the barrage of blows showered on him by Helmut
                    Leitner's powerful arms and legs in his 6th fight. Helmut (59) is the oldest man in the TOGKA and if
                    you read his profile will find since 1966 he has been taught by legends as James Rousseau, Steve
                    Arneil, Dave Mass, Ian Harris, Takeji Ogawa, all very had man, he is also the oldest man to undergo
                    the 16 man fight at 56 years of age. These fights are strenuous enough for 26 year old Karate-ka let
                    alone 56. So Mac did very well against his formidable opponent.</p>

                <p>Dave Martin took his 3rd Dan with good effort shown throughout the grading and was disappointed that
                    he could not take another 16 man fight, he has already done it for his 1st and 2nd Dan, but still
                    wanted to test his spirit again. </p>

                <p>We did not have sufficient fighters on the day to do this, so I promised Dave he could whip a Shodan
                    after the grading, and this seemed to cheer him up a little.</p>

                <p>Brad Groves and Ashley Stevenson did a good grading, showing 100% black belt standard required for
                    2nd Dan rank.</p>

                <p>Keep it up guys while I am away in England promoting Karate. </p>

                <p>I leave Australia on the 3th of May 2006 and hope to stay several years, long enough to train
                    beginners to black belt (5 years). I am sorry you don't get a black belt so quickly in the TOGKA
                    system, but this too can only benefit you personally in the long run.</p>

                <hr/>

                <h2>TOGKA 2003 DANSHA Grading</h2>
                <h3>By Sensei Graham Ravey</h3>

                <p>A Dansha grading was conducted on the 8th November 2003 by Sensei Graham Ravey.</p>

                <p>The two participants were Helmut Leitner attempting 3rd Dan and David Martin going for 2nd Dan. Both
                    students were expected to maintain a good Black Belt standard (which they did) throughout the
                    grading. The grading was held in open air with uneven ground under foot.</p>

                <p>They started out with 2 hours of various combinations, blocks, kicks, strikes and punches, gradually
                    getting more complicated as time passed by. Following that was a theory test and Kata. The students
                    had to individually perform these and show two "Bunkai" (applications) for each Kata. Next came the
                    part that keeps every promising Black Belt up the night before the test, at least dwelling on it for
                    a while, The sixteen man kumite. Both men after about two and half hours of Dansha test are required
                    to fight 16 individuals one and half minute rounds with 8 Black Belts fighting twice. This totals 24
                    minutes of continuous sparring (semi contact).</p>

                <p>Not only is a standard of fitness important for this but also a strong mental condition (spirit).
                    Irrespective how fit a participant is, he or she, if they put 100% effort into it will be worn out
                    at the end of 24 minutes. I have seen people take this test and try to conserve energy for the last
                    few fights, this is not what I am looking for. A good 16 man fight (When I say man, I of course mean
                    mankind, both man and woman) is when a students give their all physically and the spirit then
                    carries them onto the end. When we are tired and feeling sick in the stomach, a little bruised, it
                    is all to easy to give up, in fact the body sends messages to the brain for this to happen. Unless
                    the spirit can override this request we will feel like giving up throughout the sparring and it will
                    be noticeable to the examiner.</p>

                <p>I was very please with both Davids and Helmuts "Za Shin" (fighting spirit), it is also I think
                    important to mention Helmuts age at this point, he is so far at 56 the oldest person to take the 16
                    man fight. Helmut has been training now for 40 years and has trained under Sensei James Rousseau in
                    South Africa in the 1960's. In the Toshi James was noted for his "Hardness" especially in Kumite. I
                    personally met Sensei James back around 1976 on one of his trip from S.A. to Japan Yoyogi Dojo and
                    can verify what Helmut says is true.</p>

                <p>After accomplishment of the 16 man fight my black belts are allowed to wear a "Black Gi" whenever
                    they want to during training unless otherwise stated to wear the white one. The black Gi to us in
                    the TOGKA is a symbol of the 16 man fight.</p>

                <p>I know by now some of you out there are saying 16 man fight that's nothing, Kyokushinkai style does
                    100 man kumite. I had the of honour of meeting one of the first few "Gaijin" (None Japanese) to have
                    taken such a test, Sensei John Jarvis of New Zealand who in his wisdom reduced this ritual from 100
                    man to a 40 man fight for his Shodan gradings. I was in New Zealand on the invitation of Sensei
                    Jarvis teaching at one of his Gasshukus (Training Camps) when a Shodan test was taken. I always
                    remember counting to myself how many fights passed before the student in my estimation was at the
                    mercy of his attackers, it was 16. For the next 24 fights the poor man did not know if he was
                    coming. He eventually lost a couple of teeth to an over enthusiastic black belt (Nidan) as he
                    inadvertently walked into a"pulled" Jodan Kizame-tsuki, and that was "only" a 40 man fight. I vowed
                    to myself that day none of my students would lose their teeth or any other body parts in such a
                    way.</p>

                <p>I did like the idea of some kind of endurance test for the spirit and hardiness of body, so I
                    included the 16 man fight and like all good teachers should, teach by example I under went the 16
                    man fight too. I will not give my students tasks to do, that I can not do myself, being satisfied
                    that the 16 man fight can bring even the mighty to their knees. I have kept it at that number ever
                    since.</p>
            </Layout>);
    }
}

export default PastMessagesFromSenseiRavey;
